var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pageContainer"},[_c('div',{staticClass:"row position-relative m-0 p-0 w-100 big-div-quiz",style:(_vm.getPageDesign())},[(_vm.pageDesign.pageStyles.imageAlign == 'no' && _vm.getImageSrc(_vm.pageDesign.imageBlock, _vm.isDesktopView) !== '')?_c('div',{key:_vm.getImageSrc(_vm.pageDesign.imageBlock , _vm.isDesktopView),staticClass:"bg lazyload",style:(_vm.getbackgroundImage(
_vm.IsImageAdded,
_vm.pageDesign.imageBlock,
_vm.pageDesign.pageStyles.imageAlign , _vm.isDesktopView
) + _vm.getBgDivStyles())}):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showLeftImage),expression:"showLeftImage"}],staticClass:"px-0 px-0",class:[{
        'col-12 col-lg-12 col-xs-12': !_vm.isDesktopView,
        'image-division col-6': _vm.isDesktopView,
      }],style:(_vm.calculateImageWidth( _vm.pageDesign.imageBlock , _vm.isDesktopView , _vm.IsImageVariableWidthActive))},[_c('div',{class:_vm.isDesktopView ? 'image-division' : ''},[_c('div',{style:(_vm.height100(_vm.isDesktopView))},[_c('LazyImage',{key:_vm.getImageSrc(_vm.pageDesign.imageBlock , _vm.isDesktopView),class:[
              `pic-${_vm.order}`,
              _vm.getImageSrc(_vm.pageDesign.imageBlock , _vm.isDesktopView) == '/images/default_start_page.png'
                ? 'default-bg'
                : '',
            ],style:(_vm.getUniversalImageStyle(
                _vm.isDesktopView,
                _vm.pageDesign.imageBlock.style,
                    _vm.IsImageVariableWidthActive,
                    _vm.pageDesign.imageBlock
              ) +
              _vm.getImageRotation(_vm.pageDesign.imageBlock.style) +
              _vm.getImageRotation(_vm.pageDesign.imageBlock.style)),attrs:{"src":_vm.MainImgSrc(_vm.getImageSrc(_vm.pageDesign.imageBlock , _vm.isDesktopView)),"alt":""}})],1)])]),_c('div',{staticClass:"",class:[!_vm.IsImageAdded ? 'col-12' : _vm.imageAddedLayout],style:(`zoom: ${_vm.calcZoom} ;`
        + _vm.calculateContentWidth( _vm.pageDesign.imageBlock , _vm.isDesktopView ,_vm.IsImageVariableWidthActive,_vm.pageDesign.pageStyles))},[_c('div',_vm._l((_vm.pageDesign.blocksArray),function(pro,ind){return _c('div',{key:'preview' + ind},[_c('div',{staticClass:"content",staticStyle:{"position":"relative"},style:(_vm.getHeightWidth(pro.style)),attrs:{"id":"preview"}},[(pro.type == 'text')?_c('div',{style:(_vm.getColorCss(pro.style) +
                _vm.getPaddingCss(pro.style) +
                _vm.getMarginCss(pro.style,_vm.isDesktopView) +
                _vm.getColorWithOpacity(pro.style) +
                _vm.getTextRotate(pro.style) +
                _vm.getLineHeight(pro.style) +
                _vm.getTextAlign(pro.style) +
                _vm.getFontStyle(pro.style,_vm.isDesktopView)),domProps:{"innerHTML":_vm._s(pro.content)}}):_vm._e(),(pro.type == 'tooltip')?_c('div',{style:(`display:flex;justify-content: ${pro.style.position} ;  `)},[_c('button',{style:(_vm.getButtonCss(pro.style,_vm.isDesktopView) + _vm.getCircularBtn(pro.isBtnRounded))},[(pro.useIcon)?_c('span',[_c('BIcon',{attrs:{"icon":pro.selectedIcon}})],1):_c('span',{style:({
                          opacity: `${pro.style.textOpacity}%`,
                          fontSize: `${pro.style.fontSize}px`,
                        })},[_vm._v(" "+_vm._s(pro.text)+" ")])]),(pro.isTooltipVisible)?_c('ToolTipComp',{attrs:{"tooltipData":pro,"isDesktopView":_vm.isDesktopView}}):_vm._e()],1):_vm._e(),(pro.type == 'timer')?_c('div',{style:(`display:flex;justify-content: ${pro.style.position} ; flex-direction:column; `)},[_c('Timer',{attrs:{"pro":pro,"isDesktopView":_vm.isDesktopView,"isEditor":false},on:{"endCounter":_vm.endCounterAction}})],1):_vm._e(),(pro.type == 'button')?_c('div',{style:(`display:flex;justify-content: ${pro.style.position} ; `)},[_c('button',{style:(_vm.getButtonCss(pro.style,_vm.isDesktopView))},[_c('span',{style:({
                    opacity: `${pro.style.textOpacity}%`,
                    fontSize: `${pro.style.fontSize}px`,
                  })},[_vm._v(" "+_vm._s(pro.text)+" ")]),_vm._v(" "+_vm._s(pro.style.alignItems)+" ")])]):_vm._e(),(pro.type == 'html')?_c('div',{style:(_vm.getMarginCss(pro.style,_vm.isDesktopView) +
                      _vm.getColorWithOpacity(pro.style) +
                      _vm.getTextRotate(pro.style)  +
                      _vm.getTextAlign(pro.style))},[_c('HtmlElement',{attrs:{"pro":pro,"isDesktopView":_vm.isDesktopView}})],1):_vm._e()])])}),0)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showRightImage),expression:"showRightImage"}],staticClass:"px-0 px-0",class:[{
'col-12 col-lg-12 col-xs-12': !_vm.isDesktopView,
'image-division col-6': _vm.isDesktopView,
}],style:(_vm.calculateImageWidth( _vm.pageDesign.imageBlock , _vm.isDesktopView , _vm.IsImageVariableWidthActive))},[_c('div',{class:_vm.isDesktopView ? 'image-division' : ''},[_c('div',{style:(_vm.height100(_vm.isDesktopView))},[_c('LazyImage',{key:_vm.getImageSrc(_vm.pageDesign.imageBlock , _vm.isDesktopView),class:[
                  `pic-${_vm.order}`,
                  _vm.getImageSrc(_vm.pageDesign.imageBlock , _vm.isDesktopView) == '/images/default_start_page.png'
                    ? 'default-bg'
                    : '',
                ],style:(_vm.getUniversalImageStyle(
                _vm.isDesktopView,
                _vm.pageDesign.imageBlock.style,
                    _vm.IsImageVariableWidthActive,
                    _vm.pageDesign.imageBlock
              ) +
                  _vm.getImageOpacity(_vm.pageDesign.imageBlock.style) +
                  _vm.getImageRotation(_vm.pageDesign.imageBlock.style)),attrs:{"src":_vm.MainImgSrc(_vm.getImageSrc(_vm.pageDesign.imageBlock , _vm.isDesktopView)),"alt":""}})],1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }